import React from "react"

const SquareIcon = ({ isFilled }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <rect
        x="1"
        y="1.25708"
        width="13"
        height="13.3714"
        stroke="#47341E"
        fill={isFilled && "#47341E"}
      />
    </svg>
  )
}

export default SquareIcon
