import React from "react"
import { howManyBeds } from "../../helpers/functions"
import Dropdown from "./dropdown"
import * as Styled from "./index.style"

const DropdownsBlock = ({ rooms, data }) => {
  return (
    <Styled.DropdownsBlockWrapper>
      <Styled.Title>{howManyBeds(rooms)}</Styled.Title>
      {data.map(el => (
        <Dropdown key={el.name} item={el} />
      ))}
    </Styled.DropdownsBlockWrapper>
  )
}

export default DropdownsBlock
