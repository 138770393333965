import React from 'react'
import styled from "styled-components"
import AniLink from "gatsby-plugin-transition-link/AniLink"

export const StyledLink = styled(props => <AniLink {...props} />)`
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #47341e;
  gap: 10px;
  text-indent: 0;

  svg {
    transform: translateY(-1px);
  }
`