import React, { useState } from "react"
import DownloadIcon from "./icons/download-icon"
import ShareIcon from "./icons/share-icon"
import SortIcon from "./icons/sort-icon"
import SquareIcon from "./icons/square-icon"
import * as Styled from "./index.style"
import ModalContent from "./modal"
import "./modal.css"
import NotificationHint from "./notification-hint"

const AvailabilityTable = ({ data, setData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [viewedItem, setViewedItem] = useState()
  const [sortBy, setSortBy] = useState("")
  const [isAsc, setIsAsc] = useState(true)
  const [isVisible, setIsVisible] = useState(false)
  const [hintText, setHintText] = useState("")

  const openModal = item => {
    setViewedItem(item)
    setIsModalOpen(true)
  }

  const copyText = item => {
    if (typeof window !== "undefined") {
      navigator.clipboard.writeText(
        `${window.location.origin}${item.pdf.publicURL}`
      )
      setHintText("Link copied to clipboard")
      setIsVisible(true)
    }
  }

  const downloadPdf = () => {
    setHintText("Floorplan has been downloaded")
    setIsVisible(true)
  }

  const tableSort = name => {
    if (name === "name") {
      setData(prev =>
        prev.sort((a, b) =>
          isAsc
            ? b[name].localeCompare(a[name], undefined, { numeric: true })
            : a[name].localeCompare(b[name], undefined, { numeric: true })
        )
      )
    } else {
      setData(prev =>
        prev.sort((a, b) =>
          isAsc
            ? Number(b[name]) - Number(a[name])
            : Number(a[name]) - Number(b[name])
        )
      )
    }
    setSortBy(name)
    setIsAsc(bool => !bool)
  }

  const tableHeadCells = [
    { name: "name", label: "Residence", isSorted: true },
    { name: "bedrooms", label: "BEDS", isSorted: false },
    { name: "bathrooms", label: "BATHS", isSorted: true },
    { name: "terrace", label: "Terrace", isSorted: true },
    { name: "price", label: "Price", isSorted: true },
    { name: "floorPlan", label: "Floorplan", isSorted: false },
    { name: "share", label: "SHARE", isSorted: false }
  ]

  const tableBodyItems = [
    {
      name: "name",
      render: item => <Styled.ResidenceItem>{item.name}</Styled.ResidenceItem>
    },
    {
      name: "bedrooms",
      render: item => <Styled.ItemCell>{item.bedrooms}</Styled.ItemCell>
    },
    {
      name: "bathrooms",
      render: item => <Styled.ItemCell>{item.bathrooms}</Styled.ItemCell>
    },
    {
      name: "terrace",
      render: item => (
        <SquareIcon isFilled={item.terrace ? item.terrace : undefined} />
      )
    },
    {
      name: "price",
      render: item => <Styled.ItemCell>${item.price}</Styled.ItemCell>
    },
    {
      name: "floorPlan",
      render: item => (
        <Styled.FloorPlanItemCell>
          <Styled.FloorPlanWrapper>
            {item.image ? (
              <Styled.View onClick={() => openModal(item)}>View</Styled.View>
            ) : null}
            {item.pdf ? (
              <a
                onClick={downloadPdf}
                download={`${item.name}.pdf`}
                href={item.pdf.publicURL}
                target="_self"
              >
                <DownloadIcon />
              </a>
            ) : null}
          </Styled.FloorPlanWrapper>
        </Styled.FloorPlanItemCell>
      )
    },
    {
      name: "share",
      render: item => {
        return (
          <>
            {item.pdf ? (
              <Styled.ShareIconWrapper onClick={() => copyText(item)}>
                <ShareIcon />
              </Styled.ShareIconWrapper>
            ) : null}
          </>
        )
      }
    }
  ]

  return (
    <>
      <Styled.Wrapper isOpen={isModalOpen}>
        <Styled.Table>
          <Styled.TableHead>
            <tr>
              {tableHeadCells.map(head => (
                <Styled.HeadCell key={head.name}>
                  <Styled.HeadCellItem onClick={() => tableSort(head.name)}>
                    {head.label}{" "}
                    {head.isSorted ? (
                      <SortIcon isAsc={sortBy === head.name && isAsc} />
                    ) : null}
                  </Styled.HeadCellItem>
                </Styled.HeadCell>
              ))}
            </tr>
          </Styled.TableHead>
          <tbody>
            {data.map(row => (
              <Styled.TableBodyRow key={row.name}>
                {tableBodyItems.map(cell => (
                  <Styled.TableBodyCell key={cell.name}>
                    {cell.render(row)}
                  </Styled.TableBodyCell>
                ))}
              </Styled.TableBodyRow>
            ))}
          </tbody>
        </Styled.Table>
      </Styled.Wrapper>
      {isVisible ? (
        <NotificationHint
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          text={hintText}
        />
      ) : null}

      {isModalOpen && (
        <ModalContent
          item={viewedItem}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
    </>
  )
}

export default AvailabilityTable
