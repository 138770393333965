import React, { useState, useEffect } from "react"
import CategoryLink from "../categories/category-link"
import DropdownsBlock from "./dropdowns-block"
import * as Styled from "./index.style"

const AvailabilityDropdown = ({ dropdownData }) => {
  const [data, setData] = useState({
    arrays: {},
    keys: []
  })

  useEffect(() => {
    if (dropdownData) {
      const withouDublicates = dropdownData
        .filter(
          (obj, i) =>
            i === dropdownData.findIndex(o => obj.bedrooms === o.bedrooms)
        )
        .sort()
        .map(el => [el.bedrooms, []])

      const arrays = Object.fromEntries(withouDublicates)

      dropdownData.forEach(
        item => (arrays[item.bedrooms] = [...arrays[item.bedrooms], item])
      )

      setData({ arrays, keys: Object.keys(arrays) })
    }
  }, [dropdownData])

  return (
    <React.Fragment>
      <Styled.Wrapper>
        {data.keys.map(el => (
          <DropdownsBlock key={el} rooms={el} data={data.arrays[el]} />
        ))}
      </Styled.Wrapper>
    </React.Fragment>
  )
}

export default AvailabilityDropdown
