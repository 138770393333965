import styled from "styled-components"
import { mediaMax } from "../../helpers/media-queries"

export const Title = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #47341e;
`

export const Description = styled.p`
  font-weight: 400;
  text-align: ${({ isMobile }) => isMobile && 'center'};
  font-size: 13px;
  line-height: 175%;
  color: #47341e;
`

export const Section = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content space-between;
  align-items: center;
  width: calc(90% + 48px);
  margin: 80px auto 24px;
  ${mediaMax('tablet')} {
    justify-content center;
    width: 100%;
  }
`

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 24px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin: 24px;
`

export const Button = styled.button`
  background: #47341e;
  border: 1px solid #47341e;
  min-width: 170px;
  height: 35px;
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  padding-top: 4px;
  line-height: 25px;  
  text-align: center;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #EFE8DC;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
 
  span {
    margin-right: 4px;
  }

`
