import React from "react"

const CloseModalIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <circle cx="9.98532" cy="9.86179" r="9.01462" fill="#47341E" />
      <rect
        width="10.0167"
        height="0.910609"
        transform="matrix(0.707105 0.707109 -0.707105 0.707109 6.76562 5.99854)"
        fill="#EFE8DC"
      />
      <rect
        width="10.0167"
        height="0.910609"
        transform="matrix(0.707105 -0.707109 0.707105 0.707109 6.12183 13.0811)"
        fill="#EFE8DC"
      />
    </svg>
  )
}

export default CloseModalIcon
