import React, { useState, useEffect } from "react"
import { DropdownArrow } from "../../icons"
import DownloadIcon from "../availability-table/icons/download-icon"
import ShareIcon from "../availability-table/icons/share-icon"
import SquareIcon from "../availability-table/icons/square-icon"
import ModalContent from "../availability-table/modal"
import * as Styled from "./index.style"
import ShareLink from "./share-link"

const Dropdown = ({ item }) => {
  const [isInfoShown, setIsInfoShown] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [isOpenShareWindow, setIsOpenShareWindow] = useState(false)

  useEffect(() => () => setIsInfoShown(false), [])

  return (
    <>
      <Styled.DropdownWrapper onClick={() => setIsInfoShown(bool => !bool)}>
        <Styled.Residence>{item.name}</Styled.Residence>
        <DropdownArrow isOpen={isInfoShown} />
      </Styled.DropdownWrapper>
      <>
        {isInfoShown ? (
          <Styled.ItemInfoWrapper>
            <Styled.ItemRow>
              <Styled.ItemTitle>Beds</Styled.ItemTitle>
              <Styled.ItemValue>{item.bedrooms}</Styled.ItemValue>
            </Styled.ItemRow>
            <Styled.ItemRow>
              <Styled.ItemTitle>Baths</Styled.ItemTitle>
              <Styled.ItemValue>{item.bathrooms}</Styled.ItemValue>
            </Styled.ItemRow>
            <Styled.ItemRow>
              <Styled.ItemTitle>Terrace</Styled.ItemTitle>
              <Styled.ItemValue>
                <SquareIcon
                  isFilled={item.terrace ? item.terrace : undefined}
                />
              </Styled.ItemValue>
            </Styled.ItemRow>
            <Styled.ItemRow>
              <Styled.ItemTitle>Price</Styled.ItemTitle>
              <Styled.ItemValue>${item.price}</Styled.ItemValue>
            </Styled.ItemRow>
            <Styled.ItemRow>
              <Styled.ItemTitle>Floorplan</Styled.ItemTitle>
              <Styled.ItemValue>
                {item.image ? (
                  <Styled.View onClick={() => setOpenModal(true)}>
                    View
                  </Styled.View>
                ) : null}
                <span>
                  {item.pdf ? (
                    <a
                      download={`${item.name}.pdf`}
                      href={item.pdf.publicURL}
                      target="_self"
                    >
                      <DownloadIcon />
                    </a>
                  ) : null}
                </span>
              </Styled.ItemValue>
            </Styled.ItemRow>
            <Styled.ItemRow>
              <Styled.ItemTitle>Share</Styled.ItemTitle>
              {item.pdf ? (
                <Styled.ItemValue onClick={() => setIsOpenShareWindow(true)}>
                  <ShareIcon />
                </Styled.ItemValue>
              ) : null}
            </Styled.ItemRow>
          </Styled.ItemInfoWrapper>
        ) : null}
      </>
      {isOpenShareWindow ? (
        <ShareLink
          item={item}
          closeWindow={() => setIsOpenShareWindow(false)}
        />
      ) : null}
      {openModal ? (
        <ModalContent item={item} closeModal={() => setOpenModal(false)} />
      ) : null}
    </>
  )
}

export default Dropdown
