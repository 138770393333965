import React from "react"
import * as Styled from "./index.style"

const ShareButton = ({ item }) => {
  return (
    <Styled.ShareButtonWrapper
      href={item.link}
      target="_blank"
      rel="noreferrer"
    >
      <Styled.SocialIcon>{item.icon}</Styled.SocialIcon>
      <Styled.SocialMediaName>{item.name}</Styled.SocialMediaName>
    </Styled.ShareButtonWrapper>
  )
}

export default ShareButton
