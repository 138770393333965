import React, { useState } from "react"
import { EmailIcon, FacebookIcon, LinkedInIcon, TwitterIcon } from "./icons"
import * as Styled from "./index.style"
import ShareButton from "./share-button"

const ShareLink = ({ item, closeWindow }) => {
  const [copied, setCopied] = useState()

  const socialMediaArray = [
    {
      name: "Facebook",
      link: "https://uk-ua.facebook.com/",
      icon: <FacebookIcon />
    },
    {
      name: "Twitter",
      link: "https://twitter.com/",
      icon: <TwitterIcon />
    },
    {
      name: "LinkedIn",
      link: "https://www.linkedin.com/",
      icon: <LinkedInIcon />
    },
    {
      name: "Email",
      link: "https://mail.google.com/mail/?view=cm&fs=1&tf=1",
      icon: <EmailIcon />
    }
  ]

  const copyText = () => {
    if (typeof window !== "undefined") {
      navigator.clipboard.writeText(
        `${window.location.origin}${item.pdf.publicURL}`
      )
      setCopied(true)
    }
  }

  return (
    <Styled.ShareLinkWrapper>
      <Styled.Container>
        <Styled.TopSection>
          <Styled.ItemTitle>Share link</Styled.ItemTitle>
          <Styled.CloseButton onClick={closeWindow}>x</Styled.CloseButton>
        </Styled.TopSection>
        <Styled.Content>
          {socialMediaArray.map(item => (
            <ShareButton key={item.name} item={item} />
          ))}
        </Styled.Content>
        <Styled.BottomBlock>
          <Styled.LinkText>
            {`${window.location.origin}${item.pdf.publicURL}`}
          </Styled.LinkText>
          <Styled.CopylinkButton onClick={copyText}>
            {copied ? "Copied" : "Copy Link"}
          </Styled.CopylinkButton>
        </Styled.BottomBlock>
      </Styled.Container>
    </Styled.ShareLinkWrapper>
  )
}
export default ShareLink
