export const howManyBeds = (number) => {
  switch (number) {
    case 'STU': return 'STUDIO'
    case '1-BED': return 'ONE BEDROOM'
    case '2-BED': return 'TWO BEDROOM'
    case '3-BED': return 'THREE BEDROOM'
    default: return ''
  }
}

export const convertBuildingToKebabCase = (string) => {
  switch (string) {
    case 'South Tower': return 'south-tower'
    case 'North Tower': return 'north-tower'
    default: return ''
  }
}