import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`

export const DropdownsBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 100px;
`

export const Title = styled.div`
  border-bottom: 1px solid #47341E;
`

export const DropdownWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #47341E;
  padding: 8px 8px 8px 0px;
  align-items: center;
  cursor: pointer;
`
export const Residence = styled.div`
  display: flex;
  align-items: center;
`
export const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #47341E;
`

export const ItemRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 8px auto;
`

export const ItemTitle = styled.div`
  font-family: 'TWK Ghost';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 30%;
  line-height: 125%;
  display: flex;
  align-items: center;
  color: #47341E;
  min-width: fit-content;
`

export const ItemValue = styled(ItemTitle)``

export const View = styled.span`
  margin-right: 8px;
`

export const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding: 32px 0px;
`

export const ShareLinkWrapper = styled.div`
  position: absolute;
  height: -webkit-fill-available;
  width: calc(100vw - 40px);
  z-index: 10;
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  display: flex;
  width: 90%;
  height: 50vh;
  position: fixed;
  top: 25vh;
  background: #e7e1d1;
  padding: 16px;
  border-radius: 8px;
  justify-content: space-between;
  flex-direction: column;
`

export const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  align-items: center;
  margin-bottom: 32px;
`

export const SocialIcon = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
`
export const SocialMediaName = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
`

export const CloseButton = styled.button`
  height: 20px;
  width: 20px;
  border-radius: 100%;
  outline: none;
  border: none;
  background: #e7e1d1;
  border: 1px solid #47341E;
  color: #47341E;
`

export const Content = styled.div`
 display: flex;
 gap: 5vw;
 flex-wrap: wrap;
 width: 100%;
`

export const ShareButtonWrapper = styled.a`
 display flex;
 width: calc(50% - 2.5vw);
 gap: 8px;
 cursor: pointer;
 min-width: fit-content;
 border: 1px solid #47341E;
 border-radius: 8px;
 padding: 4px;
`

export const BottomBlock = styled.div`
  display: flex;
  border: 1px solid #47341E;
  border-radius: 8px;
  justify-content: space-between;
  padding: 8px;
  height: 10vh;
`

export const LinkText = styled.p`
  display: block;
  font-size: 16px;
  max-width: 60%;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const CopylinkButton = styled.button`
  display: flex;
  width: 30%;
  height: 30px;
  align-self: center;
  align-items: center;
  justify-content: center;
  border: 1px solid #47341E;
  border-radius: 8px;
  background: #e7e1d1;
  color: #47341E;
`