import React from "react"
import * as Styled from "../index.style"

const SortIcon = ({ isAsc }) => {
  return (
    <Styled.SortIconWrapper isAsc={isAsc}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="3"
        viewBox="0 0 6 3"
        fill="none"
      >
        <path
          d="M3 3L0.401923 -1.25623e-08L5.59808 4.417e-07L3 3Z"
          fill="#47341E"
        />
      </svg>
    </Styled.SortIconWrapper>
  )
}

export default SortIcon
