import React from "react"
import ArrowRight from "../../../icons/arrow-right"
import { StyledLink } from "./index.style"

const CategoryLink = ({ link, title }) => {
  return (
    <StyledLink fade duration={2} to={link}>
      <span>{title}</span>
      <ArrowRight />
    </StyledLink>
  )
}

export default CategoryLink
