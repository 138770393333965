import React, { useEffect } from "react"
import * as Styled from "./index.style"

const NotificationHint = ({ isVisible, setIsVisible, text }) => {
  useEffect(() => {
    let timer

    if (isVisible) {
      timer = setTimeout(() => {
        setIsVisible(false)
      }, 3000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [isVisible])

  return (
    <Styled.NotificationHintWrapper>
      <Styled.TextWrapper>{text}</Styled.TextWrapper>
    </Styled.NotificationHintWrapper>
  )
}

export default NotificationHint
