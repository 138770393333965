import React from "react"

const ShareIcon = () => {
  return (
    <svg
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6.42827H1V12.0854H17V6.42827H18V13.114H0V6.42827Z"
        fill="#47341E"
      />
      <path
        d="M12.1464 4.40767L9.81802 2.01272L9.81802 8.99969L8.81802 8.99969L8.81802 2.01272L6.48959 4.40767C6.29433 4.60851 5.97775 4.60851 5.78249 4.40767C5.58722 4.20683 5.58722 3.8812 5.78249 3.68036L8.96447 0.407468C9.15973 0.206628 9.47631 0.206628 9.67157 0.407468L12.8536 3.68036C13.0488 3.8812 13.0488 4.20683 12.8536 4.40767C12.6583 4.60851 12.3417 4.60851 12.1464 4.40767Z"
        fill="#47341E"
      />
    </svg>
  )
}

export default ShareIcon
