import React from "react"
import CloseModalIcon from "./icons/close-modal-icon"
import * as Styled from "./index.style"
import PageAnimation from "../animation/page-animation"
import { StaticImage } from "gatsby-plugin-image"

const ModalContent = ({ isOpen, item, closeModal }) => {
  return (
    <PageAnimation delay={0.2} duration={0.5}>
      <Styled.ModalWrapper isOpen={isOpen}>
        <Styled.ModalContainer>
          <Styled.Content>
            <StaticImage
              __imageData={item.image.childImageSharp.gatsbyImageData}
              alt={item.name}
            />
            <Styled.CloseModalButton onClick={closeModal}>
              <CloseModalIcon />
            </Styled.CloseModalButton>
          </Styled.Content>
        </Styled.ModalContainer>
      </Styled.ModalWrapper>
    </PageAnimation>
  )
}

export default ModalContent
