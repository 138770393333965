import React from "react"

const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 7.24406L6.67157 4.8491C6.47631 4.64826 6.15973 4.64826 5.96447 4.8491C5.7692 5.04994 5.7692 5.37557 5.96447 5.57641L9.14645 8.84931C9.34171 9.05015 9.65829 9.05015 9.85355 8.84931L13.0355 5.57641C13.2308 5.37557 13.2308 5.04994 13.0355 4.8491C12.8403 4.64826 12.5237 4.64826 12.3284 4.8491L10 7.24406V0.25708H9V7.24406ZM0 6.42851H1V12.0857H17V6.42851H18V13.1142H0V6.42851Z"
        fill="#47341E"
      />
    </svg>
  )
}

export default DownloadIcon
