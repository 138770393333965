import React from "react"
import "./index.style"

const SmoothScroll = ({ children }) => {
  return (
    <div id="smooth-wrapper">
      <div id="smooth-content">{children}</div>
    </div>
  )
}

export default SmoothScroll
