import React, { useEffect, useState } from "react"
import Footer from "../components/footer"
import AvailabilityTable from "../components/availability-table"
import GlobalStyle from "../helpers/global-styles"
import SmoothScroll from "../helpers/smooth-scroll"
import AvailabilityOffering from "../components/availability-offering"
import PageAnimation from "../components/animation/page-animation"
import { DefaultLayout } from "../components/layout"
import Layout from "../components/app-layout"
import AvailabilityLayout from "../components/layout/availability-layout"
import useDeviceDetect from "../hooks/use-device-detect"
import AvailabilityDropdown from "../components/availability-dropdown"
import { LinkWrapper } from "../components/availability-table/index.style"
import CategoryLink from "../components/categories/category-link"
import { graphql } from "gatsby"
import Seo from "../components/seo"

const Availability = ({ data: { allFloorPlans: { edges: floorplans } } }) => {
  const { isMobile } = useDeviceDetect()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      const response = await (await fetch("/api/availability")).json()
      // const response = await (await fetch("https://dev-mag-ruby-full-site.web.app/api/availability")).json() // for development
      const withFloorplans = response.map(unit => {
        const floorplan = floorplans.find(plan => plan.node.name === unit.name)
        return ({
          ...unit,
          image: floorplan?.node?.image,
          pdf: floorplan?.node?.pdf,
        })
      })
      setData(withFloorplans)
      setLoading(false)
    }
    fetchData()
  }, [])

  return (
    <Layout pageName={"Availability"}>
      <SmoothScroll>
        <GlobalStyle />
        <AvailabilityLayout>
          <DefaultLayout>
            <AvailabilityOffering />
          </DefaultLayout>
          {loading ? <></> :
            <DefaultLayout>
              {isMobile ? <AvailabilityDropdown dropdownData={data} /> : <AvailabilityTable setData={setData} data={data} />}
            </DefaultLayout>
          }
          <LinkWrapper>
            <CategoryLink title={"CONTACT"} link={"/register"} />
          </LinkWrapper>
          <Footer />
        </AvailabilityLayout>
      </SmoothScroll>
    </Layout>
  )
}

export default Availability

export const query = graphql`
  query Floorplans {
    allFloorPlans {
      edges {
        node {
          name
          image {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
          pdf {
            publicURL
          }
        }
      }
    }
  }
`

export const Head = () => <Seo title="Availability" />
