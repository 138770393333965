import React from "react"

const ArrowRight = () => (
  <svg
    width="25"
    height="6"
    viewBox="0 0 25 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25 3L20 0.113252L20 5.88675L25 3ZM-8.74228e-08 3.5L20.5 3.5L20.5 2.5L8.74228e-08 2.5L-8.74228e-08 3.5Z"
      fill="#47341E"
    />
  </svg>
)

export default ArrowRight
